import { DDSInfo, VehicleInfo } from 'common/model/VehicleInformation';

import { fetchDdsInfo } from 'common/api/fetchDdsInfo';
import { mapDDSInfoToVehicleInfo } from '../ewt/helpers/mapDDSInfoToVehicleInfo';
import { deriveColorName } from './helpers/deriveColorsFromStyle';

export const deriveVehicleInfo = async (vin: string): Promise<VehicleInfo> => {
  const request: Promise<DDSInfo> = fetchDdsInfo(vin);

  const payload = await request;

  if (!payload.catalogItems.length) {
    return {
      vin
    };
  }

  const vehicleInfo = mapDDSInfoToVehicleInfo({ payload, vin });

  const colors = deriveColorName(payload.catalogItems[0]?.colors);

  return {
    ...vehicleInfo,
    vin,
    ...colors
  };
};
